import React from "react"
import TextWithBorder from "../../UI/Molecules/TextWithBorder"
import Button from "../../UI/Atoms/Button"
import Text from "../../UI/Molecules/Text"
import ExternalLink from "../../UI/Atoms/ExternalLink"
import DownloadLink from "../../UI/Atoms/DownloadLink"
import InternalLink from "../../UI/Atoms/InternalLink"

const BlockContent = require("@sanity/block-content-to-react")

// these are for tailwind purge for dynamic contents
const heromedium = "h-heromedium lg:h-heromedium"
const herotall = "h-herotall lg:h-herotall"
const heroshort = "h-heroshort lg:h-heroshort"

const Hero = props => {
  const { heroHeight, heroWidth } = props
  const { image, image_alt, image_caption, hoverImages } = props
  const { headline, headline_placement, headline_color } = props
  const { body, body_color, button, button_bg_color, button_color } = props

  let block,
    blockContents,
    blockStyle = {},
    blockHeadline = ""
  let height = "heromedium"

  switch (heroHeight) {
    case "tall":
      height = "herotall"
      break
    case "short":
      height = "heroshort"
      break
    case "medium":
      height = "heromedium"
      break
    default:
      height = "heromedium"
  }

  if (headline || body) {
    blockStyle = { backgroundImage: `url(${image.asset.url})` }
    if (headline_placement === "above") {
      blockHeadline = (
        <TextWithBorder
          textType="H2"
          text={headline}
          className="col-start-1 lg:col-start-2 lg:col-span-4 col-span-7 ml-4 uppercase"
        />
      )
    }

    blockContents = (
      <>
        <div className="grid grid-cols-7 w-full py-10 md:py-20">
          <div className="col-start-1 col-span-7 lg:col-start-2 lg:col-span-5">
            {headline_placement === "overlay" && (
              <TextWithBorder
                textType="H2"
                text={headline}
                className="ml-4 lg:ml-0 uppercase font-semibold"
                textClass={`text-${headline_color ? headline_color : "white"}`}
              />
            )}

            {hoverImages && (
              <div className="grid grid-col-1 justify-center md:grid-cols-2 lg:grid-cols-4 mt-4 md:mt-10 lg:mt-32 gap-y-10 gap-x-4">
                {hoverImages.map((image, i) => (
                  <div className="grid items-center" key={i}>
                    <img
                      src={image.asset?.url}
                      alt={image_alt}
                      className="h-10 lg:h-15"
                    />
                  </div>
                ))}
              </div>
            )}

            {body && (
              <div
                className={`mt-10 mb-10 w-2/3 justify-center text-lg text-${body_color} ml-4 lg:ml-0`}
              >
                <BlockContent
                  blocks={body}
                  renderContainerOnSingleChild={false}
                  serializers={serializers}
                />
              </div>
            )}

            {button && (
              <div>
                <Button
                  {...button}
                  className={`py-3 px-12 lg:px-20 lg:py-4 bg-${button_bg_color} text-${button_color} ml-4 lg:ml-0`}
                />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  if (!headline && !body) {
    const bgImage = { backgroundImage: `url(${image?.asset?.url})` }
    blockContents = (
      <>
        <div className="pt-16.1">
          <div
            className={`w-full h-${height} bg-center bg-no-repeat bg-cover hidden lg:block`}
            style={bgImage}
          ></div>
          <img
            src={image.asset.url}
            alt={image_alt}
            caption={image_caption}
            className={`w-full lg:h-${height} bg-center bg-no-repeat bg-cover lg:hidden`}
          />
        </div>
      </>
    )
  }

  if (!heroWidth || heroWidth === "full") {
    block = (
      <div
        className={`h-full w-full lg:h-${height} overflow-hidden bg-center bg-cover bg-no-repeat`}
        style={blockStyle}
      >
        {blockHeadline}
        {blockContents}
      </div>
    )
  }

  if (heroWidth === "content") {
    block = (
      <div className="pt-20">
        <div className="grid grid-cols-7">
          {blockHeadline}
          <div
            className={`mb-4 col-start-1 col-span-7 lg:col-start-2 lg:col-span-5 mt-10 h-64 lg:h-${height}
         overflow-hidden bg-contain bg-no-repeat bg-center`}
            style={blockStyle}
          >
            {blockContents}
          </div>
        </div>
      </div>
    )
  }

  return block
}

export const BlockRenderer = props => {
  const { style = "normal" } = props.node

  if (/^h\d/.test(style)) {
    return standardHeaderStyler(style, props)
  }

  if (style === "normal") {
    return <p className="my-2">{props.children}</p>
  }

  if (style === "blockquote") {
    return <p className="my-2 text-primary-blue font-light">{props.children}</p>
  }

  if (style === "phighlight") {
    return (
      <Text
        type="P_tiny"
        value={props.children}
        className="pb-5 text-primary-light-blue-dark"
      />
    )
  }

  if (style === "shighlight") {
    return (
      <Text
        type="P_tiny"
        value={props.children}
        className="pb-5 text-primary-green-dark"
      />
    )
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const standardHeaderStyler = (style, props) => {
  let level = parseInt(style.replace(/[^\d]/g, ""))
  switch (level) {
    case 1:
      return <Text value={props.children} type="H1" />
    case 2:
      return <Text value={props.children} type="H2" />
    case 3:
      return <Text value={props.children} type="H3" />

    default:
      return (
        <div
          data-blooper-level={level}
          data-blooper-style={style}
          className="text-3xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 mb-1"
        >
          {props.children}
        </div>
      )
  }
}

export const ListRenderer = props => {
  return <ul className="list-disc">{props.children}</ul>
}

export const ListItemRenderer = props => {
  return <li className="mb-4">{props.children}</li>
}

const serializers = {
  types: {
    block: BlockRenderer,
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
  marks: {
    internalLink: ({ mark, children }) => {
      return (
        <InternalLink
          mark={mark}
          children={children}
          className="text-primary-light-blue-dark hover:underline"
        />
      )
    },
    externalLink: ({ mark, children }) => {
      return (
        <ExternalLink
          mark={mark}
          children={children}
          className="text-primary-light-blue-dark hover:underline"
        />
      )
    },
    downloadAssetLink: ({ mark, children }) => {
      return (
        <DownloadLink
          mark={mark}
          children={children}
          className="text-primary-light-blue-dark hover:underline"
        />
      )
    },
  },
}

export default Hero
